import React  from 'react';
import '../App.css';
import HeadingTwo from './HeadingTwo.js'

function Awards(props) {
    return (
    <div id="awards">
      <HeadingTwo title="Awards" />
        <ul>
          <li>Chancellor’s Oustanding Staff Award (COSA). Awarded to 24 out of ~28,000 staff at Berkeley (2022)</li>
          <li>UC Berkeley STAR Achievement Awards (2020 & 2021)</li>
          <li>SPOT Achievement Awards –Berkeley staff and managers nominate exceptional staff to receive a SPOT recognition. Received six SPOT awards since 2015.</li>
        </ul>
    </div>
    );
}


export default Awards;