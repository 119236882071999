import React, {useCallback} from 'react';
import '../App.css';

function Switch(props) {
  return (
      <label className="item-switch">
        <input onChange={props.onChange} type="checkbox" value={props.value}/>
        <span className="slider round"></span>
      </label>
  );
};

export default Switch;