import React from 'react';
import '../App.css';
import HeadingTwo from './HeadingTwo.js'
import Paragraph from './Paragraph.js'

function Education(props) {
    return (
    <div id="education">
      <HeadingTwo title="Education" />
      <Paragraph className="job-content" content="University of Wisconsin – Eau Claire" />
      <Paragraph className="job-content" content="University of Wisconsin – La Crosse" />
      <Paragraph className="job-content" content="University of Wisconsin – Madison"/>
    </div>
    );
}


export default Education;