import '../../App.css';
import Heading from '../Heading.js'
import HeadingTwo from '../HeadingTwo.js'
import Footer from '../Footer.js'
import FooterContent from '../FooterContent.js'
import React, {useEffect} from 'react'
import Skills from '../Skills.js';
import WorkExperience from '../WorkExperience.js'
import ProfessionalActivities from '../ProfessionalActivities.js'
import Awards from '../Awards.js'
import Education from '../Education.js'
import Paragraph from '../Paragraph.js'
import AboutMe from '../AboutMe.js'
import Interests from '../Interests.js'

function HomeContent(props) {

// Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    //props.setCollapsedParent;
  });

    //You should think of `img-wrapper` as the image itself. This element is what you should target for sizing/styling.
    // The `::before` pseudo-element will apear here in the Broweser(DOM) and in web-inspector.
    return (
      <div className={`item-main-content ${props.data} ${props.className} app-grid`}>
        <div className="main-content">
          <Heading level="1" title="KM Design" />
        </div>
        <div className="img-wrapper">
          <img className="banner-img" src={require('../../pages/images/estoril.jpg')} />
        </div>
    
        <div className="mobile-about-content">
          <AboutMe />
          <Interests />
          <Skills />
        </div>
    
        <Footer />
        <FooterContent />
      </div>
    );
}


export default HomeContent;