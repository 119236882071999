import background1 from "../pages/images/estoril.jpg";
import background2 from "../pages/images/sunset.jpg";
import background3 from "../pages/images/tree.jpg";
import background4 from "../pages/images/bricks.jpg";
import background5 from "../pages/images/landsend.jpg";
import background6 from "../pages/images/crane.jpg";
import background7 from "../pages/images/statue.jpg"

    const bulkImages = [
      background1, 
      background2, 
      background3, 
      background4, 
      background5, 
      background6,
      background7
    ]

export default bulkImages;