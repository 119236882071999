import '../App.css';
import HeadingTwo from './HeadingTwo.js'
import HeadingThree from './HeadingThree.js'
import Paragraph from './Paragraph.js'
import Interests from './Interests.js'
import Skills from './Skills.js'
import React from 'react'
import AboutMe from './AboutMe.js'

function SidebarRight(props) {
    return (<>
      <div className={`item-sidebar-right ${props.data}`}>
        <div className="sidebar-home-content">
          <AboutMe />
        </div>
      </div>
      <div className={`item-sidebar-right ${props.data}`}>
        <div className="sidebar-resume-content">
          <Interests />
          <Skills />
        </div>
      </div>
    </>);
}


export default SidebarRight;