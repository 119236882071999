import '../App.css';
import React from 'react'
import HeadingTwo from './HeadingTwo.js'
import HeadingThree from './HeadingThree.js'
import Paragraph from './Paragraph.js'
import Interests from './Interests.js'

function Skills(props) {
    return (<div>
        <div className="skills">
            <HeadingTwo title="Skills" />
            <ul>
              <li>Website Development</li>
              <li>App and Game Development</li>
              <li>Graphic & Product Design</li>
              <li>Marketing & Communications</li>
              <li>Data & Analysis</li>
            </ul>
          </div>
          <div className="programming-languages">
            <HeadingTwo title="Programming Languages" />
            <ul>
              <li>HTML / CSS</li>
              <li>JavaScript</li>
              <li>C#</li>
              <li>PHP</li>
            </ul>
          </div>
          <div className="tools">
            <HeadingTwo title="Tools & Tech" />
            <ul>
              <li>Adobe Creative Suite</li>
              <li>Figma</li>
              <li>Unity</li>
              <li>WordPress</li>
              <li>Airtable</li>
              <li>Excel / Sheets</li>
              <li>Slack</li>
            </ul>
          </div>
    </div>);
}


export default Skills;