import React, { Component }  from 'react';
import logo from '../logo.svg';
import '../App.css';
import { Route, Routes, Link } from "react-router-dom"

function MainMenu(props) {
  return (<div className={`item-menu ${props.className}`}>
    <div className="main-menu">
        <div className="menu-item about"><Link to="/">About</Link></div>
        <div className="menu-item spiral"><Link to="/photo">Photo</Link></div>
        <div className="menu-item projects"><Link to="/projects">Projects</Link></div>
        <div className="menu-item resume"><Link to="/resume">Resume</Link>
        <div id="skills-interests-menu-item" className="submenu-item"><a href="#skills-interests">Skills & Interests</a></div>
        <div className="submenu-item"><a href="#work-experience">Work Experience</a></div>
        <div className="submenu-item"><a href="#professional-activities">Professional Activities</a></div>
        <div className="submenu-item"><a href="#awards">Awards</a></div>
        <div className="submenu-item"><a href="#education">Education</a></div></div>
        </div>
  </div>);
}


export default MainMenu;

