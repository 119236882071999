import '../../App.css';
import Heading from '../Heading.js'
import HeadingTwo from '../HeadingTwo.js'
import Footer from '../Footer.js'
import FooterContent from '../FooterContent.js'
import React from 'react'
import Skills from '../Skills.js';
import WorkExperience from '../WorkExperience.js'
import ProfessionalActivities from '../ProfessionalActivities.js'
import Awards from '../Awards.js'
import Education from '../Education.js'

function MainContent(props) {
    return (
      <div className={`item-main-content ${props.className}`}>
        <div className="main-content">
          <Heading level="1" title="Keith McAleer" />
          <HeadingTwo title="Creative Designer and Developer" />
          <Skills />
          <WorkExperience />
          <ProfessionalActivities />
          <Awards />
          <Education />
        </div>
        
        <Footer />
        <FooterContent />
      </div>
    );
}


export default MainContent;