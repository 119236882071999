import React from 'react';
import './App.css';
import { Route, Routes, Link } from "react-router-dom"
import Switch from './components/Switch.js'
import Sidebar from './components/Sidebar.js'
import Main from './components/Main.js'
import MainMenu from './components/MainMenu.js'
import Contact from './components/Contact.js'
import DarkModeLabel from './components/DarkModeLabel.js'
import HomeContent from './components/content/HomeContent.js'
import SidebarRight from './components/SidebarRight.js'
import ProjectsContent from "./components/content/ProjectsContent.js"
import MainContent from "./components/content/MainContent.js"
import Logo from './components/Logo.js'
import SpiralContent from './components/content/SpiralContent.js'
import Hamburger from './components/Hamburger.js'


function App() {

  const [darkmode, setDarkMode] = React.useState("light-mode");
  const [collapsed, setCollapsed] = React.useState("collapsed"); // "expanded" or "collapsed"

  function handleClickDarkMode() {
    if (darkmode === "light-mode") {
      setDarkMode("dark-mode");
    } else {
      setDarkMode("light-mode");
    }
  }
  function handleClickCollapsed() {
    if (collapsed === "expanded") {
      setCollapsed("collapsed");
      console.log("collapsed");
    } else {
      setCollapsed("expanded");
      console.log("expanded");
    }
  }
  function setCollapsedParent() {
    if (collapsed==="expanded")
      setCollapsed("collapsed");
  }
  //this is wrapped in a CSS grid so elements on the top level fo this component can be assigned to the grid
  return <div className={`App ${darkmode} ${collapsed}`}> 
          <Sidebar className={`item-sidebar ${collapsed}`}/>
          <Logo />
          <Hamburger collapsed={collapsed} onClick={handleClickCollapsed} className={`item-hamburger ${collapsed}`}/>
          <MainMenu className={`${collapsed}`} />
          <Contact />
          <Main />
          <DarkModeLabel />
          <Routes>
            <Route path="/" setCollapsedParent={setCollapsedParent} element={<><HomeContent className="home-page"/><SidebarRight data="home-page" /></>} />
            <Route path="/projects" element={<><ProjectsContent className="projects-page" /><SidebarRight data="projects-page" /></>} />
            <Route path="/resume" element={<><MainContent className="resume-page" /><SidebarRight data="resume-page" /></>} />
            <Route path="/photo" element={<><SpiralContent className="spiral-page" /></>} />
          </Routes>
          <Switch onChange={handleClickDarkMode} value={darkmode} />
        </div>;

}

export default App;
