import React from 'react';
import '../App.css';
import HeadingTwo from './HeadingTwo.js'
import HeadingThree from './HeadingThree.js'
import Paragraph from './Paragraph.js'

function WorkExperience(props) {
    return (
    <div id="work-experience">
      <HeadingTwo title="Work Experience" />
      <HeadingThree title="Chief Marketing Officer" />
      <Paragraph content="UC Berkeley Sutardja Center for Entrepreneurship & Technology" />

      <Paragraph content="Berkeley, CA&nbsp;&nbsp;|&nbsp;&nbsp;2015 - Present" />

      <ul>
        <li>Collaborates with vendors, faculty, alumni, staff, students, donors, media, and contractors to design and implement strategic marketing initiatives.</li>
        <li>Supports strategic planning as a member of the leadership team and consults on messaging, mission, values, objectives, and key results.</li>
        <li>Founded and developed the Berkeley Gateway to Innovation (BEGIN) ecosystem website, helping to make the Berkeley innovation ecosystem more accessible, and facilitating relationships with faculty and staff in different areas of campus.</li>
        <li>Authors articles, develops academic brands and websites, and produces print and digital collateral to drive marketing efforts.</li>
        <li>Recruits, trains, supervises, and evaluates communications staff. Sets priorities and plans workflows.</li>
        <li>Acts as public relations contact for the Center. Receives media inquiries, facilitates interviews, pitches stories, and develops relationships with VIPs.</li>
        <li>Promoted from specialist to director to chief marketing officer in just 4 years.</li>
      </ul>

      <HeadingThree title="Communications Director" />
      <Paragraph content="UC Berkeley Industrial Engineering & Operations Research" />

      <Paragraph content="Berkeley, CA&nbsp;&nbsp;|&nbsp;&nbsp;2015 - 2022" />
      <ul>
        <li>Created strategic marketing campaigns and initiatives to support and disseminate engineering research, highlight innovative alumni, and recruit students for PhD, master’s, and undergraduate programs.</li>
        <li>Led the development of the IEOR Advisory Board, which includes more than 20 prestigious alumni, to guide strategic initiatives for the department and develop fundraising campaigns, helping to secure more than $12 million during my tenure.</li>
        <li>Authored articles, developed websites, created and developed social media channels, and served as the main contact for public relations for the IEOR department.</li>
        <li>Worked with student groups on communications campaigns, events, and other engagement activities.</li>
        <li>Recruited, managed, trained and directed communications staff. Set priorities and workflows to develop websites, create content, designs, and execute marketing activities.</li>
      </ul>

      <HeadingThree title="Executive Director" />
      <Paragraph content="Tree Davis" />

      <Paragraph content="Davis, CA&nbsp;&nbsp;|&nbsp;&nbsp;2012 - 2015" />
      <ul>
        <li>Led all aspects of a non-profit organization: program development and innovation, budget management, marketing & communications, event organizing, relationship building, fundraising, and technology development.</li>
        <li>Built community presence and brand awareness through events and marketing, and achieved program goals which strengthened relationships with key partners such as the City of Davis and the UC Davis Arborteum.</li>
        <li>Formed a new relationship with the City of West Sacramento, and led the development of a $550,000 grant proposal with the State of California, which was accepted, increasing the annual budget by over 100%.</li>
      </ul>
    </div>
    );
}


export default WorkExperience;