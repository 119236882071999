import React from 'react';
import '../App.css';
import HeadingTwo from './HeadingTwo.js'

function ProfessionalActivities(props) {
    return (
    <div id="professional-activities">
      <HeadingTwo title="Professional Activities" />
        <ul>
          <li>2019-present: Co-chair, Professional Degree Programs MarComm Network, UC Berkeley Community of Practice</li>
          <li>2017-present: Advisory Board Member, City of Berkeley Startup Cluster</li>
          <li>2017-present: Lead, Berkeley BEGIN ecosystem website</li>
          <li>2020-2022: Judge, Global Consortium of Entrepreneurship Centers Conference</li>
          <li>2021: Judge and mentor, UC Berkeley Education, Technology & Entrepreneurship course</li>
          <li>2020: Facilitator, The Leadershape Institute</li>
          <li>2019-2020: Mentor, Berkeley Staff Assembly Mentorship Program</li>
          <li>2019: Organizer, Berkeley Communications Conference (BC2)</li>
        </ul>
    </div>
    );
}


export default ProfessionalActivities;