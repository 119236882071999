import HamburgerOpen from "../hamburgerOpen.svg"
import HamburgerClosed from "../hamburger.svg"

function  Hamburger(props) {

let imgSrc = (props.collapsed=="collapsed") ? HamburgerClosed : HamburgerOpen;

	return <div onClick={props.onClick} className={props.className}>
		<img src={imgSrc} />
	</div>

}
export default Hamburger;