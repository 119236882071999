import React from 'react';
import '../App.css';


function Footer(props) {
  return (
    <div className="item-footer">

    </div>

  );
}
export default Footer;