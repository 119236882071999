import '../../App.css';
import Heading from '../Heading.js'
import HeadingTwo from '../HeadingTwo.js'
import Footer from '../Footer.js'
import FooterContent from '../FooterContent.js'
import React from 'react'
import Skills from '../Skills.js';
import WorkExperience from '../WorkExperience.js'
import ProfessionalActivities from '../ProfessionalActivities.js'
import Awards from '../Awards.js'
import Education from '../Education.js'
import background1 from "../../pages/images/estoril.jpg";
import background6 from "../../pages/images/sunset.jpg";
import background2 from "../../pages/images/tree.jpg";
import background3 from "../../pages/images/bricks.jpg";
import background4 from "../../pages/images/landsend.jpg";
import background5 from "../../pages/images/crane.jpg";
import bulkImages from '../bulkImages.js'

function SpiralContent(props) {

  const [imageIndex, setImageIndex] = React.useState(0);

  function handleClickImageSpiral() {
    (imageIndex==bulkImages.length-1) ? setImageIndex(0) : setImageIndex(imageIndex+1);
    console.log("imageindex: " + imageIndex);
    console.log("bulkImages.length-1" + bulkImages.length-1);
  }

    return (
      <div className={`item-spiral-content grid ${props.data} ${props.className}`}>
        <div className="spiral-content item img-wrapper">
          <div 
              onClick={handleClickImageSpiral}
              className="spi-one"
              style={{ 
                backgroundImage: `url(${bulkImages[imageIndex]})`
              }}  >
          </div>
          <div 
              className="spi-two"
              style={{ 
                backgroundImage: `url(${bulkImages[(imageIndex+1>bulkImages.length-1) ? 0 : imageIndex+1]})` 
              }}>
          </div>
          <div 
              className="spi-three"
              style={{ 
                backgroundImage: `url(${bulkImages[(imageIndex+2>bulkImages.length-1) ? imageIndex-4 : imageIndex+2]})` 
              }}  >
          </div>
          <div 
              className="spi-four"
              style={{ 
                backgroundImage: `url(${bulkImages[(imageIndex+3>bulkImages.length-1) ? imageIndex-3 : imageIndex+3]})` 
              }}>
          </div>
          <div 
              className="spi-five"
              style={{ 
                backgroundImage: `url(${bulkImages[(imageIndex+4>bulkImages.length-1) ? imageIndex-2 : imageIndex+4]})` 
              }}  >
          </div>
          <div 
              className="spi-six"
              style={{ 
                backgroundImage: `url(${bulkImages[(imageIndex+5>bulkImages.length-1) ? imageIndex-1 : imageIndex+5]})` 
              }}>
          </div>
        </div>
      </div>
    );
}

    

export default SpiralContent;