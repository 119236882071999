import React  from 'react';
import HeadingTwo from './HeadingTwo.js'
import '../App.css'

function Interests(props) {
  return (<div id="summary">
  	<HeadingTwo title="Interests" />
  	<div>
  		<span className="summary-label">Technology<br/>
  		<span className="summary-text">CMS, databases, programming</span></span>
  	</div>
   	<div>
  		<span className="summary-label">Design<br/>
  		<span className="summary-text">UI, UX, product & games</span></span>
  	</div>
   	<div>
  		<span className="summary-label">Marketing<br/>
  		<span className="summary-text">Campaigns & content</span></span>
  	</div>
  	<div>
  		<span className="summary-label">Operations<br/>
  		<span className="summary-text">Automation & productivity tools</span></span>
  	</div>
	</div>);
}
export default Interests;