import React from 'react';
import '../App.css';

function Sidebar(props) {
  
  return (
    <div className={`${props.className}`}>
  </div>);
}


export default Sidebar;