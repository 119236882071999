import React  from 'react';
import '../App.css';

function FooterContent(props) {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className="item-footer-content">
      <footer className="App-footer">
        <div className="footer-content">Made with care by Keith McAleer, copyright © {getCurrentYear()}
        </div>
      </footer>
    </div>

  );
}
export default FooterContent;