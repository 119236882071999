import React from 'react';
import '../App.css';

function Main(props) {
  return (      <div className="item-main">
      </div>
  );
}


export default Main;