import logo from '../logo.svg';
import React from 'react';
import '../App.css';

function Logo(props) {
  return ( <div className="item-logo">
<a href="/">
  <img src={logo} className="km-wordmark" alt="logo" width="80" />
  </a>
</div>);
}


export default Logo;
