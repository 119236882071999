import React  from 'react';
import '../App.css';
import HeadingTwo from './HeadingTwo.js'
import Paragraph from './Paragraph.js'

function AboutMe(props) {
    return (
    <div id="about-me">
      <HeadingTwo title="About Me" />
      <Paragraph content="
        Keith McAleer is a creative designer, developer and marketer. He works at the University of California, 
        Berkeley as chief marketing officer at the Sutardja Center for Entrepreneurship & Technology. 
        Throughout his career, he has helped organizations grow by developing systems, databases, software, 
        marketing campaigns, communications, and outcome-focused strategies. " />
        <br/>
      <div className="img-wrapper">
      <img className="profile-img" src={require('../pages/images/keith-mcaleer.jpeg')} />
        </div>
    </div>
    );
}


export default AboutMe;