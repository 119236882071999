import '../../App.css';
import Heading from '../Heading.js'
import HeadingTwo from '../HeadingTwo.js'
import Footer from '../Footer.js'
import FooterContent from '../FooterContent.js'
import React from 'react'
import Skills from '../Skills.js';
import WorkExperience from '../WorkExperience.js'
import ProfessionalActivities from '../ProfessionalActivities.js'
import Awards from '../Awards.js'
import Education from '../Education.js'

function ProjectsContent(props) {
    return (
      <div className={`item-main-content ${props.data} ${props.className}`}>
        <div className="main-content">
          <Heading level="1" title="Projects" />
          <HeadingTwo title="Applications" />
          <ul>
            <li>[app] <a href="https://focustimer.keithmcaleer.com" target="_blank">Focus Timer</a> Set your intention, select your focus badge, and track your pomodoros like never before!</li>
          </ul>
          <HeadingTwo title="Websites" />
          <ul>
            <li>[website] <a href="https://begin.berkeley.edu" target="_blank">BEGIN - Berkeley Gateway to Innovation</a> – Berkeley innovation and entrepreneurship ecosystem website</li>
            <li>[website] <a href="https://ieor.berkeley.edu" target="_blank">IEOR - Berkeley Industrial Engineering & Operations Research</a> – departmental website for Berkeley IEOR department.</li>
            <li>[website] <a href="https://scet.berkeley.edu" target="_blank">SCET - Sutardja Center for Entrepreneurship & Technology</a> – website for leading Berkeley entrepreneurship center</li>
          </ul>
        </div>
        <Footer />
        <FooterContent />
      </div>
    );
}


export default ProjectsContent;